<template>
	<div>
		<div>
			<Header></Header>
		</div>
		<div id="admindiv1">
			<div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item style="font-size: 13px">数据统计</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<!--主要内容-->
		<div id="forms_div2">
			<!--	选择时间-->
			<div style="margin-left: 2%">
				<el-date-picker
					v-model="value1"
					type="daterange"
					range-separator="-"
					start-placeholder="Start date"
					end-placeholder="End date"
					@change="reAll"
					format="YYYY-MM-DD"
					value-format="YYYY-MM-DD"
				/>
			</div>

			<!--	图表展示-->
			<!--人员打卡、工作时长图表-->
			<div style="display: grid;grid-template-columns: 1fr 1fr;">
				<div id="myEchartsClockNum" style="width: 100%;height: 30vh;margin-left: 50px;margin-top: 15px;"></div>
				<div id="myEchartsWorkTime" style="width: 100%;height: 30vh;margin-left: 50px;margin-top: 15px;"></div>
			</div>
		<!--	试验次数-->
			<div>
				<div id="myEchartsWorkMessage" style="width: 100%;height: 30vh;margin-left: 50px;margin-top: 15px;"></div>
			</div>
		</div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import Header from "@/views/header.vue";
import {setNowDate} from "@/utils/time";

export default {
	name: "",
	components: {Header},
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			value1: []
		}
	},
	created() {
		this.value1[0] = setNowDate(new Date())
		this.value1[1] = setNowDate(new Date())
		this.reAll()
	},
	methods: {
		reAll() {
			setTimeout(this.getUserClock, 100);
			setTimeout(this.getWorkTime, 100);
			setTimeout(this.getWorkMessage, 100);
		},
		getUserClock(){
			let x = [];
			let y = [];
			// 基于准备好的dom，初始化echarts实例
			const myChart = this.$echarts.init(document.getElementById('myEchartsClockNum'));
			//数据加载完之前先显示一段简单的loading动画
			myChart.showLoading();
			this.axios.get('/sysWork/getWorkNum', (response) => {
				if (response.obj.length !== 0) {
					myChart.hideLoading();    //隐藏加载动画
					if (response.obj.length > 15){
						for (let i = 0; i < 15; i++) {
							x.push(response.obj[i]);
							y.push(response.obj2[i]);
						}
					}else {
						for (let i = 0; i < response.obj.length; i++) {
							x.push(response.obj[i]);
							y.push(response.obj2[i]);
						}
					}

					// 防止返回图表不重新加载
					document.getElementById('myEchartsClockNum').setAttribute('_echarts_instance_', '');
					// 绘制图表
					myChart.setOption({
						title: [
							{
								text: '单位: 次',
								textStyle: {
									color: '#000000',
									fontWeight: 'normal',
									fontSize: 13
								},
								top: 15,
								left: 25
							}
						],
						legend: {
							textStyle: {
								color: '#000000'
							},
							padding: 0,
							top: '2%',
							left: '80%'
						},
						xAxis: {
							type: 'category',
							data: x,
							axisLabel: {
								interval: 0,
								fontSize: 13,
								color: '#000000'
							},

						},
						yAxis: {
							type: 'value',
							axisLine: {
								show: true //y轴线消失
							},
							splitLine: {
								show: false
							}
						},
						series: [
							{
								name: '打卡次数',
								data: y,
								type: 'bar',
								barWidth: 30,
								barGap: '50%',
								color: '#00a8ff',
								itemStyle: {
									normal: {
										label: {
											show: true, //开启显示
											position: 'top', //在上方显示
											textStyle: {
												//数值样式
												color: 'rgb(10,10,10)',
												fontSize: 10,
												fontWeight: '50000'
											},
										},
									}
								},
							}
						]
					});
				}
			}, {
				beginTime: this.value1[0],
				endTime: this.value1[1]
			})
		},
		getWorkTime(){
			let x = [];
			let y = [];
			// 基于准备好的dom，初始化echarts实例
			const myChart = this.$echarts.init(document.getElementById('myEchartsWorkTime'));
			//数据加载完之前先显示一段简单的loading动画
			myChart.showLoading();
			this.axios.get('/sysWork/getWorkTime', (response) => {
				if (response.obj.length !== 0) {
					myChart.hideLoading();    //隐藏加载动画
					if (response.obj.length > 15){
						for (let i = 0; i < 15; i++) {
							x.push(response.obj[i]);
							y.push(response.obj2[i]);
						}
					}else {
						for (let i = 0; i < response.obj.length; i++) {
							x.push(response.obj[i]);
							y.push(response.obj2[i]);
						}
					}
					// 防止返回图表不重新加载
					document.getElementById('myEchartsWorkTime').setAttribute('_echarts_instance_', '');
					// 绘制图表
					myChart.setOption({
						title: [
							{
								text: '单位: 秒',
								textStyle: {
									color: '#000000',
									fontWeight: 'normal',
									fontSize: 13
								},
								top: 15,
								left: 25
							}
						],
						xAxis: {
							type: 'category',
							data: x,
							axisLabel: {
								interval: 0,
								fontSize: 13,
								color: '#000000'
							},

						},
						yAxis: {
							type: 'value',
							axisLine: {
								show: true //y轴线消失
							},
							splitLine: {
								show: false
							}
						},
						series: [
							{
								name: '试验时间',
								tooltip: {
									valueFormatter: function(value) {
										return value.toFixed(1) + '(%)';
									},
									padding: 60
								},
								data: y,
								type: 'line',
								barWidth: 30,
								barGap: '50%',
								color: '#9c88ff',
								itemStyle: {
									normal: {
										label: {
											show: true, //开启显示
											position: 'top', //在上方显示
											textStyle: {
												//数值样式
												color: 'rgb(10,10,10)',
												fontSize: 10,
												fontWeight: '50000'
											},
										},
									}
								},
							}
						]
					});
				}
			}, {
				beginTime: this.value1[0],
				endTime: this.value1[1]
			})
		},
		getWorkMessage(){
			let x = [];
			let y = [];
			// 基于准备好的dom，初始化echarts实例
			const myChart = this.$echarts.init(document.getElementById('myEchartsWorkMessage'));
			//数据加载完之前先显示一段简单的loading动画
			myChart.showLoading();
			this.axios.get('/sysWork/getWorkMessage', (response) => {
				if (response.obj.length !== 0) {
					myChart.hideLoading();    //隐藏加载动画
					for (let i = 0; i < response.obj.length; i++) {
						x.push(response.obj[i]);
						y.push(response.obj2[i]);
					}

					// 防止返回图表不重新加载
					document.getElementById('myEchartsWorkMessage').setAttribute('_echarts_instance_', '');
					// 绘制图表
					myChart.setOption({
						title: [
							{
								text: '单位: 次',
								textStyle: {
									color: '#000000',
									fontWeight: 'normal',
									fontSize: 13
								},
								top: 15,
								left: 25
							}
						],
						legend: {
							textStyle: {
								color: '#000000'
							},
							padding: 0,
							top: '2%',
							left: '80%'
						},
						xAxis: {
							type: 'category',
							data: x,
							axisLabel: {
								interval: 0,
								fontSize: 13,
								color: '#000000'
							},

						},
						yAxis: {
							type: 'value',
							axisLine: {
								show: true //y轴线消失
							},
							splitLine: {
								show: false
							}
						},
						series: [
							{
								name: '试验次数',
								data: y,
								type: 'bar',
								barWidth: 30,
								barGap: '50%',
								color: '#fbc531',
								itemStyle: {
									normal: {
										label: {
											show: true, //开启显示
											position: 'top', //在上方显示
											textStyle: {
												//数值样式
												color: 'rgb(10,10,10)',
												fontSize: 10,
												fontWeight: '50000'
											},
										},
									}
								},
							}
						]
					});
				}
			}, {
				beginTime: this.value1[0],
				endTime: this.value1[1]
			})
		},
	}
}
</script>

<style>
#admindiv1 {
	border-radius: 5px;
	background-color: white;
	display: flex;
	width: 95%;
	height: 40px;
	margin: 10px auto 30px;
	justify-content: space-between;
}
#forms_div2{
	border-radius: 5px;
	margin: 0 auto;
	background-color: white;
	width: 95%;
	padding-top: 20px;
	padding-bottom: 20px;
	min-height: 500px;
}
</style>